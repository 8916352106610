<template>
  <div>
    <v-card width="1200" flat v-show="true">
      <div v-for="(items, index) in selectedItem.textContent" :key="index">
        <editor
          v-model="selectedItem.textContent[index]"
          :init="tiny_init"
          height="1500"
          id="tinymce"
        />
      </div>
    </v-card>
    <!-- <v-dialog fullscreen v-model="dialModel">
            <v-card color="white" tile></v-card>
        </v-dialog> -->
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
// import tinymce from "tinymce/tinymce"
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";

// import "tinymce/tinymce";
// import "tinymce/themes/silver";
// import 'tinymce/icons/default'
// import "tinymce/skins/ui/oxide/skin.css";

import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/lists";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/charmap";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/anchor";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/help";
import "tinymce/plugins/table";
import "tinymce/plugins/importcss";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/template";
import "tinymce/plugins/codesample";
import "tinymce/plugins/hr";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/toc";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/autosave";

import "tinymce/plugins/wordcount";

import contentCss from "!!raw-loader!tinymce/skins/content/default/content.min.css";
import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";
// import theme from '!!raw-loader!tinymce/themes/silver/theme.min.js'

import axios from "axios";
import tinymce from "tinymce/tinymce";

export default {
  components: {
    editor: Editor,
  },

  data() {
    return {
      // tiny_init : {
      //     height: 600,
      //     inline: true,
      //     selector: 'textarea#image-tools',
      //     visual: false,
      //     image_title: true,
      //     automatic_uploads: true,
      //     // images_upload_url: `http://10.169.130.155:5003/stand_by/upload`,
      //     // images_upload_handler: example_image_upload_handler,
      //     file_picker_types: 'image',
      //     menubar: false,
      //     plugins: [
      //         // 'export',
      //         // 'print',
      //         'pagebreak',
      //         'table',
      //         'advlist autolink lists link image charmap print preview anchor',
      //         'searchreplace visualblocks code fullscreen',
      //         'insertdatetime media table imagetools'
      //     ],
      //     // | print
      //     toolbar: 'export | pagebreak | table | fontselect  | fontsizeselect | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | insertfile undo redo | addcomment showcomments',
      //     content_style: [contentCss, contentUiCss, theme].join('\n') +'body { font-family:Times New Roman,Arial,sans-serif}',
      //     fontsize_formats : "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 19px 20px 21px 22px 23px 24px 36px 40px 49px 72px",
      //     font_formats : `Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,
      //         avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms, Sans serif=sans-serif; Courier New=courier new,
      //         courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,
      //         helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva;
      //         Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats`,

      //     skin: false,
      //     content_css: false,
      //     image_caption: true,
      //     target_list: [
      //         // { text: 'Current window', value: '' },
      //         { text: 'New window', value: '_blank' }
      //     ]
      // },

      tiny_init: {
        object_resizing: false,
        inline: true,
        readonly: 1,
        toolbar: false,
        menubar: false,
        visual: false,
        skin: false,
        content_css: false,
        // content_style: [contentUiCss].join('\n') +'body { font-family:Times New Roman,Arial,sans-serif}',
        plugins: ["print", "pagebreak"],
        content_style:
          [contentCss, contentUiCss].join("\n") +
          "body { font-family:Times New Roman,Arial,sans-serif}",
      },

      selectedItem: {},

      dialModel: true,
    };
  },

  created() {
    this.getShiyo();
  },

  methods: {
    async getShiyo() {
      // let id = 'ygBKhlN-F'
      let url = `${this.api}sekkeiKikaku/getSpecificKikaku/${this.$route.query.id}`;
      await axios
        .get(
          // `${this.api}sekkeiGyoumu/getSpecificSekkeiGyoumuFinalDataTable/${this.$route.query.id}`
          url
        )
        .then((res) => {
          this.selectedItem = res.data;
          document.title = `${this.selectedItem.docNo}-${this.selectedItem.title}`;
          // setTimeout(() =>{
          //     const tag = document.getElementsByTagName('span');
          //     console.log(tag)
          // }, 1000)

          // tag[0].setAttribute('style', 'font-size: 10px;');
        });

      await this.print();
    },

    print() {
      setTimeout(() => {
        let length = document.getElementsByTagName("table").length;
        let table = document.getElementsByTagName("table");
        console.log(table);

        for (let i = 0; i <= length - 1; i++) {
          let width = document.getElementsByTagName("table")[i].style.width;
          if (width.includes("%")) {
            console.log(i, table[i].getAttribute("style"));
            let pixelWidth = (parseFloat(width) / 100) * 1200;

            let oldAttribute = table[i].getAttribute("style").toString();
            let widthIndex = oldAttribute.indexOf("width");

            let firstConcat = oldAttribute.substring(0, widthIndex);
            let scndConcat = oldAttribute.substring(
              widthIndex + 17,
              oldAttribute.length
            );
            table[i].setAttribute(
              "style",
              `${firstConcat + scndConcat} width: ${pixelWidth}px;`
            );
            console.log(i, table[i].getAttribute("style"));
          }
        }
      }, 200);

      setTimeout(() => {
        tinymce.activeEditor.execCommand("mcePrint");
        console.log(tinymce.activeEditor.execCommand("mcePrint"), "sad");
        window.onafterprint = window.close();
      }, 200);
    },
  },
};
</script>
